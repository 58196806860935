
import { SEARCH_TABS_EN, SEARCH_TABS_FR } from '~/common/constants';

export default {
  name: 'SearchTabs',
  components: {
    Icon: () => import('~/common/shared-components/Icon.vue'),
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tabs () {
      return this.$i18n.locale === 'en' ? SEARCH_TABS_EN : SEARCH_TABS_FR;
    },
  },
  methods: {
    changeTab (tabIndex) {
      this.$emit('input', tabIndex);
    },
  },
};
