import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=2494255c&scoped=true"
import script from "./Search.vue?vue&type=script&lang=js"
export * from "./Search.vue?vue&type=script&lang=js"
import style0 from "./Search.vue?vue&type=style&index=0&id=2494255c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2494255c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchTabs: require('/app/components/search/dropdowns/SearchTabs.vue').default})
