/**
 * @description
 * By using following helper function as Nuxt middleware you can set Cache-Control headers for your pages and cache the content accordingly.
 */
const cacheControl = values => ({ res }) => {
  if (!process.server) { return; }

  const cacheControlValue = Object.entries(values)
    .map(([key, value]) => `${key}=${value}`)
    .join(',');

  res.setHeader('Cache-Control', cacheControlValue);
};

export default cacheControl;
