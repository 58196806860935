
import { seoInfo } from '@/common/mixins/seoInfo';
import { mapGetters, mapActions } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import TopPart from '~/components/homepage/TopPart';
import Features from '~/components/homepage/Features';
import Search from '~/components/search/Search';
import cacheControl from '~/common/helpers/cacheControl';

export default {
  name: 'index',
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  components: {
    LazyHydrate,
    Search,
    Features,
    TopPart,
    // https://v2.vuejs.org/v2/guide/components-dynamic-async.html
    Info: () => import('~/components/homepage/Info'),
    Subscription: () => import('~/components/homepage/Subscription'),
  },
  mixins: [seoInfo],
  async fetch () {
    const {
      departure,
      destination,
      numberOfAdults,
      numberOfChildren,
      ageOfChildren,
      dates,
      searchType,
      tripType,
    } = this.$route.query;

    const [dateFrom, dateTo] = dates ? dates.split(',') : [];
    const ages = ageOfChildren ? ageOfChildren.split(',') : [];

    numberOfAdults && this.setNumberOfAdults(+numberOfAdults);
    numberOfChildren && this.setNumberOfChildren(+numberOfChildren);

    ages.forEach((age, index) => this.setAgeOfChildren({ age: +age, id: index + 1 }));

    await this.setSearch({
      cityCodeDeparture: departure,
      cityCodeDestination: destination,
      dateStart: dateFrom,
      dateEnd: dateTo || dateFrom, // if tripType is oneWay, dateStart = dateEnd (to avoid 'undefined')
      tripType,
    });
  },
  computed: {
    ...mapGetters({
      searchData: 'search/search',
    }),
    lang () {
      return this.$i18n.locale || 'en';
    },
  },
  mounted () {
    if (
      Object.values(this.searchData).every(
        value => value !== undefined && value !== null && value !== ''
      )
    ) {
      this.triggerSearching();
    }
  },
  methods: {
    ...mapActions({
      setSearch: 'search/setSearch',
      triggerSearching: 'search/triggerSearching',
      setNumberOfAdults: 'passengers/setNumberOfAdults',
      setNumberOfChildren: 'passengers/setNumberOfChildren',
      setAgeOfChildren: 'passengers/setAgeOfChildren',
    }),
  },
  head () {
    return {
      title: this.getSEOTitle({ lang: this.lang }),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.getSEODescription({ lang: this.lang }),
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.getSEOTitle({ lang: this.lang }),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.getSEODescription({ lang: this.lang }),
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `https://www.tripeze.com${this.$route.path}`,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'Tripeze',
        },
        {
          property: 'og:image',
          content:
            'https://www.tripeze.com/_ipx/s_112x112/images/logos/tripeze-logo.png',
        },
        {
          name: 'og:image:alt',
          content:
            this.lang === 'en'
              ? 'Tripeze helps you book bus tickets and hotels across Canada and the United States'
              : "Tripeze vous aide à réserver des billets d'autobus et des hôtels partout au Canada et aux États-Unis",
        },
        {
          name: 'twitter:image',
          content:
            'https://www.tripeze.com/_ipx/s_112x112/images/logos/tripeze-logo.png',
        },
        {
          name: 'twitter:site',
          content: '@GoTripeze',
        },
        {
          name: 'twitter:creator',
          content: '@GoTripeze',
        },
        {
          'data-react-helmet': true,
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image:alt',
          content:
            this.lang === 'en'
              ? 'Tripeze helps you book bus tickets and hotels across Canada and the United States'
              : "Tripeze vous aide à réserver des billets d'autobus et des hôtels partout au Canada et aux États-Unis",
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.getSEOTitle({ lang: this.lang }),
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.getSEODescription({ lang: this.lang }),
        },
      ],
      link: [
        {
          rel: 'canonical',
          href:
            this.lang === 'en'
              ? 'https://www.tripeze.com'
              : 'https://www.tripeze.com/fr',
        },
      ],
      script: [
        {
          innerHTML: `{
          "@context": "https://schema.org",
          "@type": "Organization",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Montreal, Canada",
            "postalCode": "H3B 0G4",
            "streetAddress": "1275 Avenue des Canadiens-de-Montréal, suite 500 Montréal, QC H3B 0G4"
          },
          "email": "info@tripeze.com",
          "url": "https://www.tripeze.com",
          "logo": "https://www.tripeze.com/_ipx/s_112x112/images/logos/tripeze-logo.png",
          "name": "Tripeze",
          "telephone": "1-888-988-2205"
          }`,
          type: 'application/ld+json',
        },
        {
          innerHTML: `{
          "@context": "http://schema.org",
          "@type": "Website",
          "name": "Tripeze",
          "url": "https://www.tripeze.com/"
          }`,
          type: 'application/ld+json',
        },
        {
          innerHTML: `
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              "@type": "Question",
              "name": "${
                this.lang === 'en'
                  ? 'Why book with Tripeze?'
                  : 'Pourquoi réserver avec Tripeze ?'
              }" ,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "${
                  this.lang === 'en'
                    ? "Tripeze helps travelers book bus trips across Canada and the United States. We make it easy to compare bus schedules and available hotels, plan your bus trip and book it in one click.<br><br>We're with you before and during your trip when you need us. Our mission is to help you travel by bus with “eze”."
                    : 'Il est maintenant simple de réserver des billets de bus et des hôtels en même temps. Nous veillerons à ce que vous disposiez de tout ce dont vous avez besoin pour voyager avec « eze ».'
                }"
            }]
          }`,
          type: 'application/ld+json',
        },
      ],
    };
  },
};
