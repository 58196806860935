
export default {
  name: 'Search',
  components: {
    SearchTabs: () => import('~/components/search/dropdowns/SearchTabs'),
    BusSearch: () => import('~/components/search/tabs/BusSearch'),
    // HotelSearch: () => import('~/components/search/tabs/HotelSearch'),
    BusAndHotelsSearch: () =>
      import('~/components/search/tabs/BusAndHotelsSearch'),
  },
  data: () => ({
    activeTab: 1,
  }),
  computed: {
    isHomePage () {
      return (
        this.$route.path === '/' ||
        this.$route.path === '/fr' ||
        this.$route.path === '/en'
      );
    },
  },
  created () {
    if (
      this.$route.path.includes('bus-hotel') ||
      (this.isHomePage && this.$route.query.searchType === 'bus-hotel')
    ) {
      this.activeTab = 1;
    } else if (
      this.$route.path.includes('bus') ||
      (this.isHomePage && this.$route.query.searchType === 'bus')
    ) {
      this.activeTab = 0;
    // else if (
    //   this.$route.path.includes('hotels') ||
    //   !!this.$route.params.hotelName
    // ) {
    //   this.activeTab = 1;
    // } this.activeTab = 0;
    }
  },
};
