
import { mapGetters } from 'vuex';
import { seoInfo } from '@/common/mixins/seoInfo';
export default {
  name: 'TopPart',
  mixins: [seoInfo],
  computed: {
    ...mapGetters({
      departure: 'destination/getCurrentDeparture',
      destination: 'destination/getCurrentDestination',
      currentHotelProduct: 'hotel/getCurrentHotelProduct',
    }),
    productType () {
      if (this.$route.path.includes('hotels')) {
        return 'hotels';
      } else if (this.$route.path.includes('bus-hotel')) {
        return 'bus-hotel';
      } else if (this.$route.path.includes('bus')) {
        return 'bus';
      } else {
        return '';
      }
    },
    lang () {
      return this.$i18n.locale || 'en';
    },
    departureName () {
      if (this.departure) {
        return this.departure.name;
      } else {
        return '';
      }
    },
    destinationName () {
      if (this.destination) {
        return this.destination.name;
      } else {
        return '';
      }
    },
    isHotelProductPage () {
      return !!this.$route.params.hotelName;
    },
    SEOPageTitle () {
      return this.isHotelProductPage && this.currentHotelProduct
        ? this.getSEOHotelPageTitle(
          this.currentHotelProduct.city,
          this.currentHotelProduct.name,
          this.lang
        )
        : this.getSEOPageTitle(
          this.productType,
          this.departureName,
          this.destinationName,
          this.lang
        );
    },
    SEOPageSubtitle () {
      return this.isHotelProductPage && this.currentHotelProduct
        ? this.getSEOHotelPageSubtitle(
          this.currentHotelProduct.city,
          this.currentHotelProduct.name,
          this.lang
        )
        : this.$t('topPartPage.two');
    },
  },
};
